/* istanbul ignore file */

import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { graphql } from "gatsby";
import React from "react";
import { CtaButtonDataFragment } from "../../../graphql-types";
import ExternalLink from "../ExternalLink";

type Props = CtaButtonDataFragment & {
  id?: string;
};

export default function CtaButton({ label, link, id }: Props) {
  return (
    <div className="container max-w-lg mx-auto flex justify-center">
      <ExternalLink
        className="relative inline-flex items-center justify-end p-1 overflow-hidden text-md text-gray-900 rounded-xl group bg-gradient-to-br from-primary-400 to-primary-800 group-hover:from-secondary-600 group-hover:to-primary-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 hover:no-underline"
        href={link}
        id={id}
      >
        <span className="relative pr-2 pl-4 py-2.5 transition-all ease-in duration-75 rounded-md group-hover:bg-white text-white group-hover:text-primary-500">
          {label}
          <ArrowRightCircleIcon className="w-8 h-8 ml-4" />
        </span>
      </ExternalLink>
    </div>
  );
}

export const fragment = graphql`
  fragment CtaButtonData on CtaButton {
    label
    link
  }
`;
